import React, { useState } from 'react';

const ProfileSetting = () => {
    const [name, setName] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [error, setError] = useState('');

    const handleImageChange = (e) => {
        setProfileImage(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        formData.append('profile_image', profileImage);

        try {
            const response = await fetch('https://api.demo-ontech.cc/api/v1/update-profile', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                // Profile updated successfully
                console.log('Profile updated successfully');
                // You can navigate to another page or show a success message
            } else {
                const data = await response.json();
                setError(data.message || 'Profile update failed. Please try again.');
            }
        } catch (err) {
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <div className="container">
            <h2>Profile Setting</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Your Name:</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Profile Image:</label>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        required
                    />
                </div>
                <button type="submit">Update Profile</button>
                {error && <div className="error-message">{error}</div>} {/* Display error if any */}
            </form>
        </div>
    );
};

export default ProfileSetting;
