// src/pages/admin/layout/Sidebar.js

import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation

const Sidebar = () => {
  return (
    <aside className="sidebar">
      <button type="button" className="sidebar-close-btn">
        <iconify-icon icon="radix-icons:cross-2"></iconify-icon>
      </button>
      <div>
        <Link to="/dashboard" className="sidebar-logo">
          <img src="./assets/images/logo.png" alt="site logo" className="light-logo" />
          <img src="./assets/images/JoePulse-FF-white.png" alt="site logo" className="dark-logo" />
          <img src="./assets/images/JoePulse-icon.png" alt="site logo" className="logo-icon light" />
        </Link>
      </div>
      <div className="sidebar-menu-area">
        <ul className="sidebar-menu" id="sidebar-menu">
          <li className="dropdown">
            <Link to="/dashboard">
              <span>Dashboard</span>
            </Link>
          </li>
          <li className="dropdown mt-4">
            <Link to="/my-task">
              <span>My Task</span>
            </Link>
          </li>
          <li className="dropdown mt-4">
            <Link to="#">
              <iconify-icon icon="hugeicons:invoice-03" className="menu-icon"></iconify-icon>
              <span>User Management</span>
            </Link>
            <ul className="sidebar-submenu">
              <li>
                <Link to="/user-management">
                  <i className="ri-circle-fill circle-icon text-primary-600 w-auto"></i>
                  All Users
                </Link>
              </li>
              <li>
                <Link to="/pending-users">
                  <i className="ri-circle-fill circle-icon text-warning-main w-auto"></i>
                  Pending Users
                </Link>
              </li>
              <li>
                <Link to="/create-user">
                  <i className="ri-circle-fill circle-icon text-info-main w-auto"></i>
                  Add New User
                </Link>
              </li>
            </ul>
          </li>
          <li className="dropdown mt-4">
            <Link to="#">
              <iconify-icon icon="hugeicons:invoice-03" className="menu-icon"></iconify-icon>
              <span>Pulse</span>
            </Link>
            <ul className="sidebar-submenu">
              <li>
                <Link to="/projects">
                  <i className="ri-circle-fill circle-icon text-primary-600 w-auto"></i>
                  All Projects
                </Link>
              </li>
              <li>
                <Link to="#" data-bs-toggle="modal" data-bs-target="#exampleModalP">
                  <i className="ri-circle-fill circle-icon text-warning-main w-auto"></i>
                  Add New Project
                </Link>
              </li>
            </ul>
          </li>
          <li className="dropdown mt-4">
            <Link to="#">
              <iconify-icon icon="hugeicons:invoice-03" className="menu-icon"></iconify-icon>
              <span>Pulse Insight</span>
            </Link>
            <ul className="sidebar-submenu">
              <li>
                <Link to="/reports">
                  <i className="ri-circle-fill circle-icon text-primary-600 w-auto"></i>
                  All Reports
                </Link>
              </li>
              <li>
                <Link to="#" data-bs-toggle="modal" data-bs-target="#">
                  <i className="ri-circle-fill circle-icon text-warning-main w-auto"></i>
                  Portfolio
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
