// src/pages/admin/layout/Footer.js

import React from 'react';

const Footer = () => {
  return (
    <footer className="d-footer">
      <div className="row align-items-center justify-content-between">
        <div className="col-auto">
          <p className="mb-0">© 2024 JOE PULSE. All Rights Reserved.</p>
        </div>
        <div className="col-auto">
          {/* You can add more footer content here if needed */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
