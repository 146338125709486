// src/components/PrivateRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem('token'); // Check if the token exists in local storage

  return isAuthenticated ? children : <Navigate to="/" />; // Redirect to home if not authenticated
};

export default PrivateRoute;
