// src/pages/admin/dashboard.js

import React from 'react';
import Sidebar from './layout/Sidebar'; // Adjust the path according to your project structure
import TopBar from './layout/Topbar'; // Adjust the path according to your project structure
import Footer from './layout/Footer'; // Adjust the path according to your project structure

const Dashboard = () => {
  return (
    <>
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <main className="dashboard-main">
        {/* Navbar */}
        <TopBar />

        {/* Main content area */}
        <div className="main-content">
          <h1>Dashboard</h1>
          {/* Add more components or content as needed */}
        </div>

        {/* Footer */}
        <Footer />
      </main>

      <style>
        {`
          .navbar-search .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            inset-inline-start: 0.9375rem;
            font-size: 1.125rem;
            color: var(--black);
          }
        `}
      </style>
    </>
  );
};

export default Dashboard;
