import React from 'react';
import { Link } from 'react-router-dom';

const ChangePassword = () => {
    return (
        <section className="auth forgot-password-page bg-base d-flex flex-wrap">  
            <div className="auth-left d-lg-block d-none">
                <div className="d-flex align-items-center flex-column h-100 justify-content-center">
                    <img src="assets/images/auth/reset.jpg" alt="Reset" />
                </div>
            </div>
            <div className="auth-right px-24 d-flex flex-column justify-content-center">
                <div className="max-w-464-px mx-auto w-100">
                    <div>
                        <h4 className="mb-12">Forgot Password</h4>
                        <p className="mb-32 text-secondary-light text-lg">
                            Enter the email address associated with your account and we will send you a link to reset your password.
                        </p>
                    </div>
                    <form action="process-reset-password.php" method="post">
                        <div className="icon-field mb-20">
                            <span className="icon top-50 translate-middle-y">
                                <iconify-icon icon="mage:email"></iconify-icon>
                            </span>
                            <input
                                type="email"
                                name="email"
                                className="form-control h-56-px bg-neutral-50 radius-12"
                                placeholder="Enter Email"
                                value=""
                                readOnly
                            />
                        </div>
                        <div className="position-relative mb-20">
                            <div className="icon-field">
                                <span className="icon top-50 translate-middle-y">
                                    <iconify-icon icon="solar:lock-password-outline"></iconify-icon>
                                </span> 
                                <input
                                    type="password"
                                    name="password"
                                    className="form-control h-56-px bg-neutral-50 radius-12"
                                    required
                                    placeholder="New Password"
                                />
                            </div>
                            <span className="toggle-password ri-eye-line cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light" data-toggle="#your-password"></span>
                        </div>
                        <div className="position-relative mb-20">
                            <div className="icon-field">
                                <span className="icon top-50 translate-middle-y">
                                    <iconify-icon icon="solar:lock-password-outline"></iconify-icon>
                                </span> 
                                <input
                                    type="password"
                                    name="password_confirmation"
                                    className="form-control h-56-px bg-neutral-50 radius-12"
                                    required
                                    placeholder="Confirm Password"
                                />
                            </div>
                            <span className="toggle-password ri-eye-line cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light"></span>
                        </div>
                        <button type="submit" className="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32">Continue</button>
                        <div className="text-center">
                            <Link to="/index" className="text-primary-600 fw-bold mt-24">Back to Sign In</Link>
                        </div>
                        <div className="mt-20 text-center text-sm">
                            <p className="mb-20">Already have an account? <Link to="/index" className="text-primary-600 fw-semibold">Sign In</Link></p>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ChangePassword;
