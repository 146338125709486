import React from 'react';
import { Link } from 'react-router-dom'; 
const ForgotPassword = () => {
    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
    };

    return (
        <section className="auth forgot-password-page bg-base d-flex flex-wrap">  
            <div className="auth-left d-lg-block d-none">
                <div className="d-flex align-items-center flex-column h-100 justify-content-center">
                    <img src="https://i.pinimg.com/originals/67/93/9e/67939e4042893e1e0d035dc3761b9c36.jpg" alt="" />
                </div>
            </div>
            <div className="auth-right py-32 px-24 d-flex flex-column justify-content-center">
                <div className="max-w-464-px mx-auto w-100">
                    <div>
                        <h4 className="mb-12">Forgot Password</h4>
                        <p className="mb-32 text-secondary-light text-lg">
                            Enter the email address associated with your account and we will send you a link to reset your password.
                        </p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="icon-field">
                            <span className="icon top-50 translate-middle-y">
                                <iconify-icon icon="mage:email"></iconify-icon>
                            </span>
                            <input
                                type="email"
                                name="email"
                                className="form-control h-56-px bg-neutral-50 radius-12"
                                placeholder="Enter Email"
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32">
                            Continue
                        </button>

                        <div className="text-center">
                        <Link to="/" className="text-primary-600 fw-bold mt-24">Back to Sign In</Link>
                        </div>
                        
                        <div className="mt-120 text-center text-sm">
                            <p className="mb-0">
                                Already have an account? <Link to="/" className="text-primary-600 fw-semibold">Sign In</Link>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ForgotPassword;
