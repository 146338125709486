import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Register = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const apiUrl = "https://api.demo-ontech.cc/api/v1/register";
    const verifyEmailUrl = "https://api.demo-ontech.cc/api/v1/verify-email";

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (response.ok) {
                // After registration, redirect to the email verification page
                await fetch(verifyEmailUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email }),
                });
                navigate('/profile-setting');
            } else {
                setError(data.message || 'Registration failed. Please try again.');
            }
        } catch (err) {
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <>
            <style>
                {`
                .logo-set {
                    padding: 0.5% 0;
                }
                .logo-set img {
                    width: 180px;
                    height: auto;
                    padding-left: 10px;
                }
                .register-set h3 {
                    font-size: 40px;
                    text-align: center;
                }
                .register-set .row {
                    height: 88vh;
                }
                .ctg-set {
                    background: #239AFF;
                    width: fit-content;
                    margin: 5% auto;
                    padding: 10px;
                    color: #fff;
                }
                .ctg-set div {
                    align-items: center;
                }
                .ctg-set span {
                    background: #fff;
                    padding: 5px 10px;
                }
                .divider-set {
                    display: flex;
                    gap: 2px;
                    justify-content: center;
                    align-items: center;
                    margin: 10px 0;
                }
                .divider-set span {
                    height: 1px;
                    width: 13%;
                    background: rgb(142, 142, 142);
                }
                .register-form-set form {
                    width: fit-content;
                    background: #000;
                }
                .register-form-set span {
                    width: fit-content;
                }
                input[type="email"] {
                    background: #fff;
                    border: 1px solid;
                }
                .register-form-set span input {
                    width: 240px;
                    padding: 10px;
                }
                .register-form-set span button {
                    width: 110px;
                    padding: 10px 20px;
                    background: #000;
                    color: #fff;
                    border: none;
                }
                .content-p-set {
                    text-align: center;
                    font-size: 12px;
                    font-weight: 500;
                    margin: 10px 0;
                }
                @media (max-width: 410px) {
                    .register-set h3 {
                        font-size: 32px;
                    }
                }
                @media (max-width: 378px) {
                    .register-set h3 {
                        font-size: 28px;
                    }
                    .ctg-set {
                        font-size: 12px;
                    }
                    .register-form-set span input {
                        width: 190px;
                    }
                    .register-form-set span button {
                        width: 100px;
                    }
                }
                `}
            </style>
            <section className="logo-set">
                <div className="container">
                    <div className="row">
                        <Link to="/">
                            <div className="col-6">
                                <img src="assets/images/logo.png" alt="Company Logo" className="logo-set" />
                            </div>
                        </Link>
                    </div>
                </div>
            </section>

            <section className="register-set">
                <div className="container">
                    <div className="row">
                        <div className="col-12 m-auto text-center">
                            <div className="content-set">
                                <h3>You’re one click away</h3>
                                <h3>from less busywork</h3>
                            </div>
                            <div className="ctg-set">
                                <div className="d-flex gap-2 justify-content-center">
                                    <Link to="/your-google-signup-url" style={{ textDecoration: 'none', color: '#fff' }}>
                                        <span className="me-3">
                                            <img src="assets/images/google-icon.png" alt="Google Sign-In" />
                                        </span>
                                        Continue with your Google work account
                                    </Link>
                                </div>
                            </div>
                            <div className="divider-set">
                                <span></span>OR<span></span>
                            </div>
                            <div className="register-form-set">
                                <div className="d-flex justify-content-center">
                                    <form onSubmit={handleSubmit}>
                                        <span>
                                            <input
                                                type="email"
                                                name="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="name@company.com"
                                                required
                                            />
                                        </span>
                                        <span>
                                            <button type="submit">Continue</button>
                                        </span>
                                    </form>
                                </div>
                                {error && <div className="error-message">{error}</div>}
                            </div>
                            <div className="content-p-set">
                                <p>
                                    By signing up, I agree to the Joe Pulse <Link to="/your-privacy-policy-url">Privacy Policy</Link> and <Link to="/your-terms-of-service-url">Terms of Service</Link>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Register;
