// src/App.js

import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ForgotPassword from './pages/auth/Forgot-Password';
import ChangePassword from './pages/auth/Change-Password';
import ProfileSetting from './pages/auth/Profile-Setting';
import NotFound from './pages/NotFound'; // Import your NotFound component
import Dashboard from './pages/admin/dashboard'; // Ensure you import your dashboard component
import PrivateRoute from './components/PrivateRoute'; // Import your PrivateRoute component

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/profile-setting" element={<ProfileSetting />} />
                    <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} /> {/* Protected route */}
                    <Route path="*" element={<NotFound />} /> {/* Fallback route */}
                </Routes>
            </div>
        </Router>
    );
}

export default App;
