// src/pages/auth/Login.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Redirect logged-in users to dashboard
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard'); // Redirect to dashboard if already logged in
    }
  }, [navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous errors

    try {
      const response = await fetch('https://api.demo-ontech.cc/api/v1/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
      });

      if (response.ok) {
        const data = await response.json();
        // Store the token in local storage
        localStorage.setItem('token', data.data.token);

        // Redirect to the dashboard
        navigate('/dashboard');
      } else if (response.status === 422) {
        const errorData = await response.json();
        setError(errorData.message || 'Invalid credentials. Please check your input.');
      } else {
        setError('An error occurred. Please try again later.');
      }
    } catch (error) {
      setError('Network error. Please check your connection.');
    }
  };

  return (
    <section className="auth bg-base d-flex flex-wrap">
      <style>
        {`
          .ctg-set {
            background: #239AFF;
            width: 100%;
            margin: 5% auto auto;
            border-radius: 10px;
            padding: 10px;
            color: #fff;
          }

          .ctg-set div {
            align-items: center;
          }

          .ctg-set span {
            background: #fff;
            padding: 5px 10px;
          }
        `}
      </style>
      <div className="auth-left d-lg-block d-none">
        <div className="d-flex align-items-center flex-column h-100 justify-content-center">
          <img src="assets/images/auth/login.jpg" alt="" />
        </div>
      </div>
      <div className="auth-right py-32 px-24 d-flex flex-column justify-content-center">
        <div className="max-w-464-px mx-auto w-100">
          <div>
            <Link to="/" className="mb-40 max-w-290-px">
              <img src="assets/images/logo.png" alt="" />
            </Link>
            <h4 className="mb-12">Sign In to your Account</h4>
            <p className="mb-32 text-secondary-light text-lg">Welcome back! please enter your detail</p>
          </div>
          {error && <p className="text-danger">{error}</p>}
          <form onSubmit={handleLogin}>
            <div className="icon-field mb-16">
              <span className="icon top-50 translate-middle-y">
                <iconify-icon icon="mage:email"></iconify-icon>
              </span>
              <input 
                type="email" 
                name="email" 
                className="form-control h-56-px bg-neutral-50 radius-12" 
                placeholder="Email" 
                required 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="position-relative mb-20">
              <div className="icon-field">
                <span className="icon top-50 translate-middle-y">
                  <iconify-icon icon="solar:lock-password-outline"></iconify-icon>
                </span> 
                <input 
                  type={showPassword ? "text" : "password"} 
                  className="form-control h-56-px bg-neutral-50 radius-12" 
                  id="your-password" 
                  name="password" 
                  required 
                  placeholder="Password" 
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <span 
                onClick={togglePasswordVisibility} 
                className="cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light"
              >
                {showPassword ? 
                   <iconify-icon icon="akar-icons:eye" />: 
                   <iconify-icon icon="akar-icons:eye-closed" /> 
                }
              </span>
            </div>
            <div className="">
              <div className="d-flex justify-content-between gap-2">
                <div className="form-check style-check d-flex align-items-center">
                  <input className="form-check-input border border-neutral-300" type="checkbox" value="" id="remember" />
                  <label className="form-check-label" htmlFor="remember">Remember me</label>
                </div>
                <Link to="/forgot-password" className="text-primary-600 fw-medium">Forgot Password?</Link>
              </div>
            </div>
            <button type="submit" className="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32">Sign In</button>
            <div className="mt-32 center-border-horizontal text-center">
              <span className="bg-base z-1 px-4">Or sign in with</span>
            </div>
            <div className="ctg-set">
              <div className="d-flex gap-2 justify-content-center">
                <a href="google/sign/up" style={{ textDecoration: 'none', color: '#fff' }}>
                  <span className="me-3"><img src="assets/images/google-icon.png" alt="google-icon" /></span> 
                  Continue with your Google work account
                </a>
              </div>
            </div>
            <div className="mt-32 text-center text-sm">
              <p className="mb-0">Don’t have an account? <Link to="/register" className="text-primary-600 fw-semibold">Sign Up</Link></p>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
