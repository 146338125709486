import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Topbar = () => {
  const [user, setUser] = useState({ name: '', profile_img: '', user_type: 'User' });
  const navigate = useNavigate();

  // Fetch user data
  const fetchUserData = async () => {
    const token = localStorage.getItem('token'); // Retrieve the token from local storage

    try {
      const response = await fetch('https://api.demo-ontech.cc/api/v1/me', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`, // Use the token in the request
        },
      });
      const data = await response.json();
      if (response.ok) {
        setUser({
          name: data.name,
          profile_img: data.profile_img || 'https://www.physiotec.net/wp-content/uploads/2020/10/unknown.jpg',
          user_type: data.user_type || 'User', // Handle user type
        });
      } else {
        console.error('Failed to fetch user data:', data);
        if (data.message === 'Unauthorized') {
          // Handle unauthorized access (e.g., redirect to login)
          navigate('/login');
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  // Logout function
  const handleLogout = async () => {
    const token = localStorage.getItem('token'); // Get the token for logout

    try {
      const response = await fetch('https://api.demo-ontech.cc/api/v1/logout', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, // Use the token in the request
        },
      });
      const data = await response.json();
      if (response.ok) {
        console.log('Logout successful:', data);
        // Clear the token from local storage
        localStorage.removeItem('token');
        // Redirect to login page
        navigate('/');
      } else {
        console.error('Logout failed:', data);
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <nav className="navbar-header">
      <div className="row align-items-center justify-content-between">
        <div className="col-auto">
          <div className="d-flex flex-wrap align-items-center gap-4">
            <button type="button" className="sidebar-toggle" aria-label="Toggle Sidebar">
              <iconify-icon icon="heroicons:bars-3-solid" className="icon text-2xl non-active"></iconify-icon>
              <iconify-icon icon="iconoir:arrow-right" className="icon text-2xl active"></iconify-icon>
            </button>
            <button type="button" className="sidebar-mobile-toggle" aria-label="Toggle Mobile Sidebar">
              <iconify-icon icon="heroicons:bars-3-solid" className="icon"></iconify-icon>
            </button>
            <form className="navbar-search" role="search">
              <input type="text" name="search" placeholder="Search" aria-label="Search" />
              <button type="submit" aria-label="Submit Search">
                <iconify-icon icon="ion:search-outline" className="icon"></iconify-icon>
              </button>
            </form>
          </div>
        </div>
        <div className="col-auto">
          <div className="d-flex flex-wrap align-items-center gap-3">
            <button type="button" data-theme-toggle="" className="theme-toggle-button" aria-label="Toggle Light Theme">
              Light
            </button>
            {/* Messages Dropdown */}
            <div className="dropdown">
              <button className="has-indicator notification-button" type="button" data-bs-toggle="dropdown" aria-label="Messages">
                <iconify-icon icon="mage:email" className="text-primary-light text-xl"></iconify-icon>
              </button>
              <div className="dropdown-menu to-top dropdown-menu-lg p-0">
                <div className="header">
                  <h6 className="text-lg text-primary-light fw-semibold mb-0">Messages</h6>
                  <span className="notification-count">05</span>
                </div>
                <div className="dropdown-body max-h-400-px overflow-y-auto scroll-sm pe-4">
                  {/* Message Items */}
                </div>
                <div className="text-center py-12 px-16">
                  <a href="#" className="text-primary-600 fw-semibold text-md">See All Messages</a>
                </div>
              </div>
            </div>
            {/* Notifications Dropdown */}
            <div className="dropdown">
              <button className="has-indicator notification-button" type="button" data-bs-toggle="dropdown" aria-label="Notifications">
                <iconify-icon icon="iconoir:bell" className="text-primary-light text-xl"></iconify-icon>
              </button>
              <div className="dropdown-menu to-top dropdown-menu-lg p-0">
                <div className="header">
                  <h6 className="text-lg text-primary-light fw-semibold mb-0">Notifications</h6>
                  <span className="notification-count">05</span>
                </div>
                <div className="dropdown-body max-h-400-px overflow-y-auto scroll-sm pe-4">
                  {/* Notification Items */}
                </div>
                <div className="text-center py-12 px-16">
                  <a href="#" className="text-primary-600 fw-semibold text-md">See All Notifications</a>
                </div>
              </div>
            </div>
            {/* User Profile Dropdown */}
            <div className="dropdown">
              <button className="user-profile-button" type="button" data-bs-toggle="dropdown" aria-label="User Profile">
                <img
                  src={user.profile_img}
                  alt="Profile Image"
                  className="w-40-px h-40-px object-fit-cover rounded-circle"
                />
              </button>
              <div className="dropdown-menu to-top dropdown-menu-sm">
                <div className="user-profile-header">
                  <h6 className="text-lg text-primary-light fw-semibold mb-2">{user.name}</h6>
                  <span className="text-secondary-light fw-medium text-sm" style={{ textTransform: 'capitalize' }}>{user.user_type}</span>
                </div>
                <ul className="user-profile-options">
                  <li><a href="javascript:">Profile</a></li>
                  <li><a href="javascript:">Settings</a></li>
                  <li><button onClick={handleLogout} className="logout-button">Logout</button></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Topbar;
